.message-box {
  display: flex;
  flex-direction: row;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 30px;
  border-left: 3px solid;
  font-weight: bold;

  &.info {
    border-left-color: #4fa9f8;
    color: #4fa9f8;
    background-color: #ecf7fe;
  }
}

/* ----------------------------- */
/* ==SPACING                     */
/* ----------------------------- */

$spacing: 0.4rem;

// ---------------------
// MARGIN TOP
// ---------------------

.mt-0 { margin-top: $spacing * 0; }
.mt-1 { margin-top: $spacing * 1; }
.mt-2 { margin-top: $spacing * 2; } // 8
.mt-3 { margin-top: $spacing * 3; }
.mt-4 { margin-top: $spacing * 4; } // 16
.mt-5 { margin-top: $spacing * 5; }
.mt-6 { margin-top: $spacing * 6; } // 24
.mt-7 { margin-top: $spacing * 7; }
.mt-8 { margin-top: $spacing * 8; } // 32
.mt-9 { margin-top: $spacing * 9; }
.mt-10 { margin-top: $spacing * 10; } // 40
.mt-11 { margin-top: $spacing * 11; }
.mt-12 { margin-top: $spacing * 12; } // 48
.mt-13 { margin-top: $spacing * 13; }
.mt-14 { margin-top: $spacing * 14; } // 56
.mt-15 { margin-top: $spacing * 15; }
.mt-16 { margin-top: $spacing * 16; } // 64

// ---------------------
// MARGIN BOTTOM
// ---------------------

.mb-0 { margin-bottom: $spacing * 0; }
.mb-1 { margin-bottom: $spacing * 1; }
.mb-2 { margin-bottom: $spacing * 2; } // 8
.mb-3 { margin-bottom: $spacing * 3; }
.mb-4 { margin-bottom: $spacing * 4; } // 16
.mb-5 { margin-bottom: $spacing * 5; }
.mb-6 { margin-bottom: $spacing * 6; } // 24
.mb-7 { margin-bottom: $spacing * 7; }
.mb-8 { margin-bottom: $spacing * 8; } // 32
.mb-9 { margin-bottom: $spacing * 9; }
.mb-10 { margin-bottom: $spacing * 10; } // 40
.mb-11 { margin-bottom: $spacing * 11; }
.mb-12 { margin-bottom: $spacing * 12; } // 48
.mb-13 { margin-bottom: $spacing * 13; }
.mb-14 { margin-bottom: $spacing * 14; } // 56
.mb-15 { margin-bottom: $spacing * 15; }
.mb-16 { margin-bottom: $spacing * 16; } // 64

// ---------------------
// MARGIN LEFT
// ---------------------

.ml-0 { margin-left: $spacing * 0; }
.ml-1 { margin-left: $spacing * 1; }
.ml-2 { margin-left: $spacing * 2; } // 8
.ml-3 { margin-left: $spacing * 3; }
.ml-4 { margin-left: $spacing * 4; } // 16
.ml-5 { margin-left: $spacing * 5; }
.ml-6 { margin-left: $spacing * 6; } // 24
.ml-7 { margin-left: $spacing * 7; }
.ml-8 { margin-left: $spacing * 8; } // 32
.ml-9 { margin-left: $spacing * 9; }
.ml-10 { margin-left: $spacing * 10; } // 40
.ml-11 { margin-left: $spacing * 11; }
.ml-12 { margin-left: $spacing * 12; } // 48
.ml-13 { margin-left: $spacing * 13; }
.ml-14 { margin-left: $spacing * 14; } // 56
.ml-15 { margin-left: $spacing * 15; }
.ml-16 { margin-left: $spacing * 16; } // 64

// ---------------------
// MARGIN RIGHT
// ---------------------

.mr-0 { margin-right: $spacing * 0; }
.mr-1 { margin-right: $spacing * 1; }
.mr-2 { margin-right: $spacing * 2; } // 8
.mr-3 { margin-right: $spacing * 3; }
.mr-4 { margin-right: $spacing * 4; } // 16
.mr-5 { margin-right: $spacing * 5; }
.mr-6 { margin-right: $spacing * 6; } // 24
.mr-7 { margin-right: $spacing * 7; }
.mr-8 { margin-right: $spacing * 8; } // 32
.mr-9 { margin-right: $spacing * 9; }
.mr-10 { margin-right: $spacing * 10; } // 40
.mr-11 { margin-right: $spacing * 11; }
.mr-12 { margin-right: $spacing * 12; } // 48
.mr-13 { margin-right: $spacing * 13; }
.mr-14 { margin-right: $spacing * 14; } // 56
.mr-15 { margin-right: $spacing * 15; }
.mr-16 { margin-right: $spacing * 16; } // 64

// ---------------------
// PADDING TOP
// ---------------------

.pt-0 { padding-top: $spacing * 0; }
.pt-1 { padding-top: $spacing * 1; }
.pt-2 { padding-top: $spacing * 2; } // 8
.pt-3 { padding-top: $spacing * 3; }
.pt-4 { padding-top: $spacing * 4; } // 16
.pt-5 { padding-top: $spacing * 5; }
.pt-6 { padding-top: $spacing * 6; } // 24
.pt-7 { padding-top: $spacing * 7; }
.pt-8 { padding-top: $spacing * 8; } // 32
.pt-9 { padding-top: $spacing * 9; }
.pt-10 { padding-top: $spacing * 10; } // 40
.pt-11 { padding-top: $spacing * 11; }
.pt-12 { padding-top: $spacing * 12; } // 48
.pt-13 { padding-top: $spacing * 13; }
.pt-14 { padding-top: $spacing * 14; } // 56
.pt-15 { padding-top: $spacing * 15; }
.pt-16 { padding-top: $spacing * 16; } // 64

// ---------------------
// PADDING BOTTOM
// ---------------------

.pb-0 { padding-bottom: $spacing * 0; }
.pb-1 { padding-bottom: $spacing * 1; }
.pb-2 { padding-bottom: $spacing * 2; } // 8
.pb-3 { padding-bottom: $spacing * 3; }
.pb-4 { padding-bottom: $spacing * 4; } // 16
.pb-5 { padding-bottom: $spacing * 5; }
.pb-6 { padding-bottom: $spacing * 6; } // 24
.pb-7 { padding-bottom: $spacing * 7; }
.pb-8 { padding-bottom: $spacing * 8; } // 32
.pb-9 { padding-bottom: $spacing * 9; }
.pb-10 { padding-bottom: $spacing * 10; } // 40
.pb-11 { padding-bottom: $spacing * 11; }
.pb-12 { padding-bottom: $spacing * 12; } // 48
.pb-13 { padding-bottom: $spacing * 13; }
.pb-14 { padding-bottom: $spacing * 14; } // 56
.pb-15 { padding-bottom: $spacing * 15; }
.pb-16 { padding-bottom: $spacing * 16; } // 64

// ---------------------
// PADDING LEFT
// ---------------------

.pl-0 { padding-left: $spacing * 0; }
.pl-1 { padding-left: $spacing * 1; }
.pl-2 { padding-left: $spacing * 2; } // 8
.pl-3 { padding-left: $spacing * 3; }
.pl-4 { padding-left: $spacing * 4; } // 16
.pl-5 { padding-left: $spacing * 5; }
.pl-6 { padding-left: $spacing * 6; } // 24
.pl-7 { padding-left: $spacing * 7; }
.pl-8 { padding-left: $spacing * 8; } // 32
.pl-9 { padding-left: $spacing * 9; }
.pl-10 { padding-left: $spacing * 10; } // 40
.pl-11 { padding-left: $spacing * 11; }
.pl-12 { padding-left: $spacing * 12; } // 48
.pl-13 { padding-left: $spacing * 13; }
.pl-14 { padding-left: $spacing * 14; } // 56
.pl-15 { padding-left: $spacing * 15; }
.pl-16 { padding-left: $spacing * 16; } // 64

// ---------------------
// PADDING RIGHT
// ---------------------

.pr-0 { padding-right: $spacing * 0; }
.pr-1 { padding-right: $spacing * 1; }
.pr-2 { padding-right: $spacing * 2; } // 8
.pr-3 { padding-right: $spacing * 3; }
.pr-4 { padding-right: $spacing * 4; } // 16
.pr-5 { padding-right: $spacing * 5; }
.pr-6 { padding-right: $spacing * 6; } // 24
.pr-7 { padding-right: $spacing * 7; }
.pr-8 { padding-right: $spacing * 8; } // 32
.pr-9 { padding-right: $spacing * 9; }
.pr-10 { padding-right: $spacing * 10; } // 40
.pr-11 { padding-right: $spacing * 11; }
.pr-12 { padding-right: $spacing * 12; } // 48
.pr-13 { padding-right: $spacing * 13; }
.pr-14 { padding-right: $spacing * 14; } // 56
.pr-15 { padding-right: $spacing * 15; }
.pr-16 { padding-right: $spacing * 16; } // 64

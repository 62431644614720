/* ----------------------------- */
/* ==Reset (base)                */
/* ----------------------------- */

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    min-height: 100%;
    font-size: $font-size-html;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    overflow-wrap: break-word;
}

body {
    min-height: 100%;
    height: 100vh;
    margin: 0;
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $color_grey__600;
    background-color: $color_white;
    overflow-x: hidden;
}

img,
iframe {
    display: block;
    margin: 0 auto;
    border-style: none;
}

img,
iframe,
svg,
video {
    vertical-align: middle;
}

img,
input,
textarea,
select,
video,
svg,
iframe {
    max-width: 100%;
    outline: none;
    padding: 0;
}

iframe,
img,
input,
select,
textarea {
    height: auto;
}

input,
select,
textarea {
    font-family: $font-family-base;
}

/* ----------------------------- */
/* ==Reset (forms)               */
/* ----------------------------- */

a,
button,
input,
label,
select,
textarea,
[tabindex] {
    touch-action: manipulation;
}

button,
input,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    vertical-align: middle;
}

button,
select,
a {
    cursor: pointer;
}

button,
input:not([type="radio"]):not([type="checkbox"]):not(.p-inputtext):not(.p-inputtextarea),
select,
textarea {
    border: 0;
}

button,
input {
    overflow: visible;
}

input,
textarea {
    min-height: 2.4rem;
    padding:0.85rem 0 0.85rem 0.85rem;
    width:100%;
}

button,
select {
    text-transform: none;
    -webkit-appearance: none; // Warning Select and Button appearance
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

form,
fieldset {
    border: none;
}

figure {
    position: relative;
    display: block;
    margin: 0 auto;
}

label {
    display: inline-block;
    // cursor: pointer;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
    white-space: pre-wrap;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/* ----------------------------- */
/* ==Table                       */
/* ----------------------------- */

table {
    width:100%;
    margin:0;
}

th {
    text-align: unset;
    font-weight: inherit;
}

/* ----------------------------- */
/* ==Helpers                     */
/* ----------------------------- */

.visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition: none !important;
    }
}
//----------------------
// Layout
// ---------------------

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

//----------------------
// Flexbox
// ---------------------

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

//----------------------
// Grid
// ---------------------

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

//----------------------
// Display
// ---------------------

.block {
  display: block;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

//----------------------
// Box Alignment
// ---------------------

// Justify Content

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

// Align Items

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

// Align Self
.self-start {
  align-self: flex-start;
}

//----------------------
// Typography
// ---------------------

// Text Color

.text-action {
  color: $color_action;
}

.text-information {
  color: $color_information;
}

.text-critical {
  color: $color_critical;
}

.text-confirmation {
  color: $color_confirmation;
}

.text-warning {
  color: $color_warning;
}

// Text Color Purple

.text-purple-100 {
  color: $color_purple__100;
}

.text-purple-200 {
  color: $color_purple__200;
}

.text-purple-300 {
  color: $color_purple__300;
}

.text-purple-400 {
  color: $color_purple__400;
}

// Text Color Grey

.text-white {
  color: $color_white;
}

.text-grey-100 {
  color: $color_grey__100;
}

.text-grey-200 {
  color: $color_grey__200;
}

.text-grey-300 {
  color: $color_grey__300;
}

.text-grey-400 {
  color: $color_grey__400;
}

.text-grey-500 {
  color: $color_grey__500;
}

.text-grey-600 {
  color: $color_grey__600;
}

.text-grey-700 {
  color: $color_grey__700;
}

.text-black {
  color: $color_black;
}

// Text transform

.uppercase {
  text-transform: uppercase;
}

// Text decoration
.underline {
  text-decoration: underline;
}

// Font size

.text-xs {
  font-size: 1rem;
}

.text-xs-1 {
  font-size: 1.1rem;
}

.text-sm {
  font-size: 1.2rem;
}

.text-md {
  font-size: 1.4rem;
}

.text-base {
  font-size: 1.6rem;
}

.text-lg {
  font-size: 1.8rem;
}

.text-lg-1 {
  font-size: 2rem;
}

.text-lg-2 {
  font-size: 2.2rem;
}

// Letter spacing

.tracking-widest {
  letter-spacing: 0.1rem;
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

//----------------------
// Sizing
// ---------------------

/* width */
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

/* height */
.h-full {
  height: 100%;
}

.w-100 {
  min-width: 12rem;
}

//----------------------
// Cursor
// ---------------------

.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
/*-------------------------------------------*\
  Scrollbar

  Customize the appearance of scroll bars.
\*-------------------------------------------*/

::-webkit-scrollbar-track {
    border: none;
    background-color: $color_white;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: $color_white;
}

::-webkit-scrollbar-thumb {
    background-color: #C2C2C2;
}

/*-------------------------------------------*\
  Selection

  Customize the appearance of content selected by a mouse.
\*-------------------------------------------*/

// Works on common browsers
::selection {
    background-color: lighten($color_action, 5%);
    color: $color_white;
}

// Mozilla based browsers
::-moz-selection {
    background-color: lighten($color_action, 5%);
    color: $color_white;
}

// For Other Browsers
::-o-selection {
    background-color: lighten($color_action, 5%);
    color: $color_white;
}

::-ms-selection {
    background-color: lighten($color_action, 5%);
    color: $color_white;
}

// For Webkit
::-webkit-selection {
    background-color: lighten($color_action, 5%);
    color: $color_white;
}
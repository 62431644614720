/* ----------------------------- */
/* ==Variables                   */
/* ----------------------------- */

//----------------------
// Utility colors
// ---------------------

$color_action: #3636B9;
$color_information: #2DB0FF;
$color_critical: #FF5252;
$color_confirmation: #2CBF87;
$color_warning: #F3B63B;

/* Purple */

$color_purple__100: #E6E6F9;
$color_purple__200: #D3D3F7;
$color_purple__300: #8787D9;
$color_purple__400: #152D62;

/* Grey */

$color_white : #FFFFFF;
$color_grey__100 : #F8FAFF;
$color_grey__200 : #F2F4FB;
$color_grey__300 : #D5DFEA;
$color_grey__400 : #B6C7D9;
$color_grey__500 : #74889D;
$color_grey__600 : #46525F;
$color_grey__700 : #33333B;
$color_black: #000000;

// $color_divider: 	    #33333B;
// $color_level_0: 		#FFFFFF;
// $color_level_1: 	    #F8FAFF;
// $color_level_1_accent:  #E6E6F9;
// $color_level_2:         #D5DFEA;
// $color_level_2_accent:  #D3D3F7;
// $color_level_3: 	    #B6C7D9;
// $color_level_3_accent: 	#152D62;
// $color_level_4: 	    #74889D;

// $color_contrast: 	    #33333B;
// $color_default: 		#46525F;
// $color_subtle: 	        #74889D;
// $color_nonessential: 	#F2F4FB;


// ---------------------
// Border
// ---------------------

$border-radius_0: 0rem;
$border-radius_4: 0.4rem;
$border-radius_6: 0.6rem;
$border-radius_12: 1.2rem;

// ---------------------
// Elevation
// ---------------------

$elevation__0: 0rem 0.4rem 2rem rgba(65, 74, 85, 0.1);
$elevation__1: 0rem 0rem 7.2rem rgba(65, 74, 85, .15);
$elevation__2: 0rem 0rem 6rem 0rem #E4E7EB;
$elevation__3: 0rem 0.4rem 1.6rem rgba(65, 74, 85, .15);
$elevation__4: 0rem 0rem 0.4rem rgba(65, 74, 85, .15);